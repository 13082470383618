/* eslint-disable import/no-unused-modules */
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Helmet from '../components/Helmet';
import { PageLayout } from '../shared/layouts';
import withI18n from '../hocs/withI18n';
import { CALENDLY_URL } from '../config';

export const Head = withI18n(() => {
  return <Helmet pageName="requestDemo" />;
});

const RequestDemo = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <PageLayout className="p-0">
      <div
        className="calendly-inline-widget"
        data-url={CALENDLY_URL}
        style={{ minWidth: '320px', height: '700px' }}
      />
    </PageLayout>
  );
};

export default RequestDemo;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
